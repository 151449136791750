import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, ProgressBar, Modal, Form } from 'react-bootstrap';
import { AiFillInfoCircle } from 'react-icons/ai';

import SignSection from './SignSection';
import ScriptSection from './ScriptSection';
import { generateScript } from './backendInterface';

import './FirstDateView.css';

const FirstDateView = () => {
    const [charOneName, setCharOneName] = useState('');
    const [charTwoName, setCharTwoName] = useState('');

    const [selectedCharOneZodiac, setSelectedCharOneZodiac] = useState(null);
    const [selectedCharTwoZodiac, setSelectedCharTwoZodiac] = useState(null);

    const [activeScene, setActiveScene] = useState(0);
    const [script, setScript] = useState([]);
    const [progress, setProgress] = useState(0);

    const [showIntroModal, setShowIntroModal] = useState(true); // For the introductory modal
    const [showEditModal, setShowEditModal] = useState(false); // For the edit prompts modal
    const [isHovered, setIsHovered] = useState(false);

    const handleCloseIntroModal = () => setShowIntroModal(false);
    const handleShowEditModal = () => setShowEditModal(true);
    const handleCloseEditModal = () => setShowEditModal(false);

    const promptTitles = ['System Prompt', 'Character Prompts', 'Scene 1 Prompt', 'Scene 2 Prompt', 'Scene 3 Prompt', 'Scene 4 Prompt'];
    const [scenePrompts, setScenePrompts] = useState([
        `You are the director and writer of a dating reality show that includes a scene where 2 individuals are going on a first date. Write a British dark humor style script of this date.
Based on their star sign, pick an appropriate strongly negative characteristic for each person, e.g. controlling, insecure, self-obsessed, etc.
At the beginning of the date each person should act and speak like a completely normal, well-balanced person and this negative characteristic should be completely hidden. As the date proceeds the negative characteristic will start to subtly reveal itself in little mannerisms, subtle details of recounted stories, or through little details of the conversation.
As the date progresses, this characteristic reveals itself more and more in how they speak, their manners, how they react to the other person and events in the restaurant, and in the stories that they tell about their lives.`,
        `The characters are ${charOneName}, who is a ${selectedCharOneZodiac} and ${charTwoName}, who is a ${selectedCharTwoZodiac}.`,
        `Establish the scene.
The characters arrive at slightly different times, introduce themselves and order drinks. The drinks each chooses should if possible mirror their star sign attributes, OR negative characteristic.
Have one character initiate a very brief what is your star sign conversation. The conversation should quickly touch on what sign the person is and what that says about their personality. Do not dwell on astrology, move on to other topics.
Make some brief awkward small talk, but then suddenly connect over a moment of shared understanding.
Have them each explain why they are looking for love again and what they are looking for in a partner. This explanation should include wry observations on love and life.
Have them each share what went wrong in their previous relationship. These stories should say as much about them as their ex-partner.
Have them each tell a story about how unbearable their job is. As appropriate, include ONE humorous example of either; 
    a. How future and meaningless their job really is
    b. A new coworker who has caused turmoil in their workplace. Describe this turmoil and how it was caused
    c. A manipulative boss who expects them to work every waking hour, and has devious ways of making this happen
    d. A counter-productive office bonding event that went hilariously off-the-rails`,
        `The second scene should show the characters getting to know each other. This could be good or bad, depending on their zodiac alignments.
At one point disrupt their conversation is disrupted by ONE randomly selected amusing event from this list;
    a. One of them spots an ex at another table and really really doesn't want to be noticed back
    b. One of them touches the other’s foot under the table by accident and there is a brief misunderstanding that is resolved comically
    c. The restaurant fire alarm goes off and the sprinklers activate momentarily
    d. A Nearby Table’s Marriage Proposal goes Horribly Wrong
    e. A diner at a nearby table has a Violent Food Allergy Reaction`,
        `The last scene is where each character’s negative behavioral characteristic is on full-display and is noticed fully by the other person and vice versa.
This culminates in the individuals deciding if there will be a second date. Randomly decide between this being a 'YES' or 'NO'.`,
        `After the date each person is interviewed by the reality show producer about what happened. In this short interview, each interviewee’s negative characteristic is on full display, and their recall of the evening's events is heavily distorted by this. Each interview concludes with the person commenting dryly on the difficulty of finding love and how many crazy people there are out there.`,
    ]);

    useEffect(() => {
        setShowIntroModal(true);
    }, []);

    useEffect(() => {
        const content = `The characters are ${charOneName}, who is a ${selectedCharOneZodiac} and ${charTwoName}, who is a ${selectedCharTwoZodiac}.`;
        handleInputChange(1, content);
    }, [charOneName, selectedCharOneZodiac, charTwoName, selectedCharTwoZodiac]);

    const generateDate = async () => {
        setScript([]);
        setActiveScene(0);
        setProgress(3);
        await generateScript(scenePrompts, setScript, setProgress);
    };

    const handleInputChange = (index, content) => {
        const newEditableMessages = [...scenePrompts];
        newEditableMessages[index] = content;
        setScenePrompts(newEditableMessages);
    };

    useEffect(() => {
        const handleKeyDown = event => {
            // Check if CMD (or CTRL for Windows) + E is pressed
            if ((event.metaKey || event.ctrlKey) && event.key === 'e') {
                event.preventDefault(); // Prevent default behavior (like focusing browser search)
                handleShowEditModal(); // Show the modal
            }
        };

        // Add the event listener
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <Container fluid>
            <Row style={{ height: '75vh' }}>
                <Col className="border p-4 shadow rounded m-2">
                    <Row className="justify-content-center ">
                        <Card style={{ width: '40%', padding: '0px', margin: '20px' }}>
                            <Card.Header>
                                <input type="text" value={charOneName} onChange={e => setCharOneName(e.target.value)} className="character-name-input" placeholder="Enter name..." />
                            </Card.Header>
                            <Card.Img variant="top" src="char1.png" className="square-img" />
                            <Card.Body>
                                <SignSection selectedZodiac={selectedCharOneZodiac} setSelectedZodiac={setSelectedCharOneZodiac} />
                            </Card.Body>
                        </Card>
                        <Card style={{ width: '40%', padding: '0px', margin: '20px' }}>
                            <Card.Header>
                                <input type="text" value={charTwoName} onChange={e => setCharTwoName(e.target.value)} className="character-name-input" placeholder="Enter name..." />
                            </Card.Header>
                            <Card.Img variant="top" src="char2.webp" className="square-img" />
                            <Card.Body>
                                <SignSection selectedZodiac={selectedCharTwoZodiac} setSelectedZodiac={setSelectedCharTwoZodiac} />
                            </Card.Body>
                        </Card>
                    </Row>
                    <Row>
                        <Button onClick={generateDate}>See Their First Date</Button>
                    </Row>
                    <Row className="mt-3">{progress > 0 && progress < 100 && <ProgressBar now={progress} variant="success" animated />}</Row>
                </Col>
                <Col md={6} className="border p-4 shadow rounded m-2">
                    <ScriptSection script={script} activeScene={activeScene} setActiveScene={setActiveScene} />
                </Col>
            </Row>

            {/* Introductory Modal */}
            <Modal show={showIntroModal} onHide={handleCloseIntroModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Welcome to the First Date Experiment!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Here, you can create two characters, assign them zodiac signs, and see how their first date plays out based on their personalities and zodiac compatibility.</p>
                    <p>Start by entering the names of the characters, choosing their zodiac signs, and then click &quot;See Their First Date&quot; to generate a script.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseIntroModal}>
                        Got it!
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Prompts Modal */}
            <Modal show={showEditModal} onHide={handleCloseEditModal} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Prompts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {scenePrompts.map((message, index) => (
                        <Form.Group key={index} controlId={`message-${index}`} className="mt-2">
                            <Form.Label className="fw-bold">{promptTitles[index]}</Form.Label>
                            {index === 1 && (
                                <>
                                    <AiFillInfoCircle
                                        color="grey"
                                        size={20}
                                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                                        onMouseEnter={() => setIsHovered(true)} // Show message on hover
                                        onMouseLeave={() => setIsHovered(false)} // Hide message when not hovered
                                    />
                                    {isHovered && <span style={{ color: 'red', marginLeft: '8px' }}>This is populated via the character cards</span>}
                                </>
                            )}
                            <Form.Control as="textarea" rows={3} value={message} onChange={e => handleInputChange(index, e.target.value)} disabled={index === 1} />
                        </Form.Group>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseEditModal}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default FirstDateView;
